import type { User } from "@prisma/client";
import type { Params } from "react-router";
import { formatDate } from "~/utils";

export const PREVIEW_EPISODE_KEY = "preview";

export function createEpisodeKey(scheduledAt: Date) {
  return scheduledAt.toISOString().slice(0, 10);
}

export function episodeShortName(
  episode: { episodeKey: string; scheduledAt: Date },
  user: { locale: string; timeZone: string },
) {
  return episode.episodeKey === PREVIEW_EPISODE_KEY
    ? "Preview"
    : formatDate(episode.scheduledAt, user, {
        weekday: "long",
        month: "long",
        day: "numeric",
      });
}

export function episodeLongName(
  episode: { episodeKey: string; scheduledAt: Date },
  user: { locale: string; timeZone: string },
) {
  const dayOfWeek = formatDate(episode.scheduledAt, user, { weekday: "long" });
  const date = formatDate(episode.scheduledAt, user, {
    month: "long",
    day: "numeric",
  });
  return `${dayOfWeek} Sumcast, ${date}`;
}

export function requireEpisodeKey(params: Params) {
  if (!params.episodeKey) {
    throw new Response("Not Found", { status: 404 });
  }
  return params.episodeKey;
}

export interface EpisodeId {
  userId: string;
  episodeKey: string;
}

export function episodeChannel(episodeId: EpisodeId) {
  return `${episodeId.userId}/${episodeId.episodeKey}`;
}

export function nextEpisodePublishDay({ scheduledAt }: { scheduledAt: Date }, user: User, noOnPrefix = false) {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (scheduledAt.toDateString() === today.toDateString()) {
    return "today";
  }
  if (scheduledAt.toDateString() === tomorrow.toDateString()) {
    return "tomorrow";
  }
  return `${noOnPrefix ? "" : "on "}${formatDate(scheduledAt, user, {
    weekday: "long",
    month: "long",
    day: "numeric",
  })}`;
}
